<template>
  <div class="darg-to-dress-game-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>

    <div class="game-content" :class="'lesson-' + lessonNO">
      <Star
        :totalStarNumber="bgImgList.length"
        :currentIndex="step"
        v-if="hasStar"
      />
      <div class="title-area" v-if="titleInfo">
        <div class="title">
          <div class="title-pinyin pinyin font-pinyin-medium">
            {{ titleInfo.pinyin }}
          </div>
          <div class="title-hanzi font-hanzi-medium">{{ titleInfo.hanzi }}</div>
        </div>
      </div>
      <div class="background-area">
        <img :src="bgImg" class="bg-img" />
        <template v-for="(item, index) in bgImgList">
          <img
            :src="item.bgImg"
            class="bg-img-item"
            :key="index + 'bg'"
            v-show="dispearIdList.indexOf(item.index) !== -1"
          />
        </template>
      </div>
      <div
        class="drop-area"
        :class="'dropBox-' + item.index"
        v-for="(item, index) in bgImgList"
        :key="index + 'drop'"
      >
        <div
          :class="{ dispear: dispearIdList.indexOf(dragIndex) === -1 }"
          @drop.stop="handleDropImg($event, item)"
          @dragover.stop="allowDrop($event)"
          draggable="true"
        />
      </div>

      <div class="drag-area">
        <div
          v-for="(item, index) in optionImgList"
          class="option-item"
          :class="'option-' + item.index"
          :key="index + 'drag'"
        >
          <img
            :class="{ notShow: dragIndex === item.index }"
            v-show="beforeDispearIdList.indexOf(item.index) === -1"
            :src="item.image"
            draggable="true"
            @dragstart.stop="handleDragImg($event, item)"
            @drop.stop="Sdrop($event)"
            @dragend.stop="dragend()"
          />
        </div>
      </div>
    </div>
    <div
      class="hanzi-item"
      v-for="(item, index) in bgImgList"
      :key="index"
      v-show="showHnaziIndex === index && hasSentence"
    >
      <div class="pinyin sentence-hanzi font-pinyin-medium">
        {{ item.pinyin }}
      </div>
      <div class="sentence-hanzi font-hanzi-medium">{{ item.hanzi }}</div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import Star from "@/components/Course/GamePage/Star";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
export default {
  name: "DragToGressGame",
  props: {
    optionImgList: {
      type: Array,
      require: true,
      default: () => [],
    },
    bgImgList: {
      type: Array,
      require: true,
      default: () => [],
    },
    bgImg: {
      type: String,
      require: true,
    },
    lessonNO: {
      type: Number,
      require: true,
    },
    titleInfo: {
      type: Object,
      require: false,
      default: () => {},
    },
    hasStar: {
      type: Boolean,
      require: false,
      default: true,
    },
    hasSentence: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    PageButton,
    Star,
  },
  data() {
    return {
      clickIndex: 0,
      isLastStep: false,
      showHnaziIndex: false,
      dispearIdList: [],
      beforeDispearIdList: [],
      dragId: null,
      dragIndex: null,
      beforeDragIndex: null,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: !this.isSpeakingPage ? "look" : "speak",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
      dropImgSocketInfo: {},
      dragImgSocketInfo: {},
      step: 0,
    };
  },
  watch: {
    dropImgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2005701,
          data: { value },
          text: "DragToGressGame drag socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    dragImgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2005702,
          data: { value },
          text: "DragToGressGame drop socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on("dropImgEvent", ({ item }) => {
      this.handleDropImg("socket", item, true);
    });
    this.$bus.$on("dragImgEvent", ({ item }) => {
      this.handleDragImg("socket", item, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("dropImgEvent");
    this.$bus.$off("dragImgEvent");
  },
  methods: {
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    handleDragImg(event, item, isFromSocket = false) {
      if (!isFromSocket) {
        this.dragImgSocketInfo = {
          item,
          value: Math.random(),
        };
      }
      this.dragId = item.id;
      this.beforeDragIndex = item.index;
      this.dragIndex = item.id;
      // console.log(this.dragIndex, "拖拽对象");
      if (this.dragIndex === item.index) {
        // console.log("拖拽中");
      }
      if (this.lessonNO == 332) {
        this.beforeDispearIdList.splice(0, 1);
      }
    },
    handleDropImg(event, item, isFromSocket = false) {
      console.log("拖拽成功");
      if (!isFromSocket) {
        this.dropImgSocketInfo = {
          item,
          value: Math.random(),
        };
      }
      if (
        item.id === this.dragId &&
        this.dispearIdList.indexOf(item.index) === -1
      ) {
        // this.dispearIdList.push(id);
        this.dragIndex = item.index;
        this.beforeDispearIdList.push(this.beforeDragIndex);
        this.dispearIdList.push(this.dragIndex);
        // console.log(this.dispearIdList);
        if (this.titleInfo) {
          this.showHnaziIndex = item.index - 1;
        }
        playCorrectSound(true, false);
        this.showHnaziId = item.index;
        this.step++;
        if (this.step === this.bgImgList.length) {
          this.isLastStep = true;
          startConfetti();
          playCorrectSound();
        }
      } else {
        playCorrectSound(false);
      }
    },
    Sdrop: function (event) {
      event.preventDefault();
    },
    allowDrop: function (event) {
      event.preventDefault();
    },
    dragend() {
      this.dragIndex = null;
    },
  },
};
</script>
<style lang="scss" >
.lesson-17-container {
  .dropBox-4 {
    .dispear {
      height: 20% !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.option-List {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.title-area {
  z-index: 11;
  position: absolute;
  left: 0;
  top: 10%;
  width: auto;
  height: auto;
  padding: 20px;
  background: #cd4c3f;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  color: #fff;
  .title {
    .title-pinyin,
    .title-hanzi {
      text-align: center;
    }
  }
}
.lesson-30 {
  .title-area {
    width: 20%;
  }
}
.hanzi-item {
  width: 40%;
  margin: 0 auto;
  background: #cd4c3f;
  position: absolute;
  bottom: 15%;
  left: 30%;
  padding: 1.5% 0%;
  color: #fff;
  border-radius: 40px;
  .sentence-hanzi,
  .sentence-pinyin {
    text-align: center;
  }
}
.darg-to-dress-game-container {
  background: #fff;
  border-radius: 48px;
  position: relative;
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;
    .background-area {
      width: 100%;
      height: 100%;
      position: relative;
      //   width: fit-content;
      .bg-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 58px;
      }
      .bg-img-item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 58px;
      }
    }
    .drop-area {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      div {
        // background: #000;
        position: absolute;
        cursor: pointer;
        z-index: 6;
        opacity: 0.5;
      }
    }
    .dropBox-1 {
      div {
        // background: #000;
        width: 13%;
        height: 17%;
        top: 12%;
        left: 44%;
      }
    }
    .dropBox-2 {
      div {
        // background: red;

        width: 13%;
        height: 6%;
        top: 19%;
        left: 44%;
      }
    }

    .dropBox-3 {
      div {
        width: 13%;
        height: 10%;
        top: 37%;
        left: 44%;
      }
    }
    .dropBox-4 {
      div {
        width: 19%;
        height: 10%;
        top: 60%;
        left: 41%;
      }
    }
    .dropBox-5 {
      div {
        width: 24%;
        height: 8%;
        top: 83%;
        left: 39%;
      }
    }
    .dropBox-6 {
      div {
        width: 13%;
        height: 12%;
        top: 46%;
        left: 44%;
      }
    }
    .drag-area {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      img {
        cursor: pointer;
        width: 90%;
        -webkit-user-drag: inherit;
      }
      .notShow {
        opacity: 0;
      }
      .dispear {
        opacity: 0;
      }
      .option-item {
        position: absolute;
        -webkit-user-drag: auto;
        z-index: 8;
      }
      .option-1 {
        width: 18%;
        height: 18%;
        top: 5%;
        left: 4%;
      }
      .option-2 {
        width: 10%;
        height: 10%;
        top: 12%;
        left: 27%;
      }
      .option-3 {
        width: 30%;
        height: 15%;
        top: 27%;
        left: 1.2%;
      }
      .option-4 {
        width: 15%;
        height: 10%;
        top: 55%;
        left: 4%;
      }
      .option-5 {
        width: 20%;
        height: 10%;
        top: 33%;
        right: 5%;
      }
      .option-6 {
        width: 22%;
        height: 20%;
        top: 50%;
        right: 5%;
      }
    }
  }
  .lesson-24 {
    .drag-area {
      .option-1 {
        width: 6%;
        height: 6%;
        top: 6%;
        left: 42%;
      }
      .option-2 {
        width: 6%;
        height: 6%;
        top: 6%;
        left: 52%;
      }
      .option-3 {
        width: 6%;
        height: 6%;
        top: 6%;
        left: 62%;
      }
      .option-4 {
        width: 6%;
        height: 6%;
        top: 6%;
        left: 72%;
      }
      .option-5 {
        width: 6%;
        height: 6%;
        top: 6%;
        left: 82%;
      }
    }
  }
  .lesson-24 {
    .dropBox-1 {
      div {
        width: 6%;
        height: 11%;
        top: 48%;
        left: 29%;
      }
    }
    .dropBox-2 {
      div {
        width: 6%;
        height: 11%;
        top: 50%;
        left: 10%;
      }
    }
    .dropBox-3 {
      div {
        width: 6%;
        height: 11%;
        top: 50%;
        left: 48%;
      }
    }
    .dropBox-4 {
      div {
        width: 6%;
        height: 11%;
        top: 50%;
        left: 85%;
      }
    }
    .dropBox-5 {
      div {
        width: 6%;
        height: 11%;
        top: 50%;
        left: 66%;
      }
    }
  }

  .lesson-30 {
    .drag-area {
      .option-1 {
        // background:#000;
        width: 6%;
        height: 6%;
        top: 35.4%;
        left: 2%;
      }
      .option-2 {
        // background:#000;
        width: 6%;
        height: 6%;
        top: 33%;
        left: 10%;
      }
      .option-3 {
        // background:#000;
        width: 6%;
        height: 6%;
        top: 34.5%;
        left: 18%;
      }
      .option-4 {
        // background:#000;
        width: 6%;
        height: 6%;
        top: 34.7%;
        left: 26%;
      }
      .option-5 {
        // background:#000;
        width: 6%;
        height: 6%;
        top: 33%;
        left: 34%;
      }
      .option-6 {
        // background:#000;
        width: 6%;
        height: 6%;
        top: 37%;
        left: 42%;
      }
    }
  }
  .lesson-30 {
    .dropBox-1 {
      div {
        // background:#000;
        width: 6%;
        height: 11%;
        top: 66%;
        left: 13%;
      }
    }
    .dropBox-2 {
      div {
        // background:#000;
        width: 6%;
        height: 11%;
        top: 66%;
        left: 22%;
      }
    }
    .dropBox-3 {
      div {
        // background:#000;
        width: 6%;
        height: 11%;
        top: 66%;
        left: 31%;
      }
    }
    .dropBox-4 {
      div {
        // background:#000;
        width: 5%;
        height: 7%;
        top: 26%;
        left: 80%;
      }
    }
    .dropBox-5 {
      div {
        // background:#000;
        width: 5%;
        height: 9%;
        top: 38%;
        left: 68%;
      }
    }
    .dropBox-6 {
      div {
        // background:#000;
        width: 6%;
        height: 9%;
        top: 53%;
        left: 80%;
      }
    }
  }
  .lesson-33 {
    .drag-area {
      .option-1 {
        top: 45%;
        left: 2%;
      }
      .option-2 {
        top: 35%;
        left: 5%;
      }
      .option-3 {
        width: 20%;
        top: 10%;
        left: 78%;
      }
      .option-4 {
        top: 37%;
        left: 84%;
      }
    }
    .dropBox-1 {
      div {
        // background:#000;
        // opacity:0.5;
        width: 18%;
        height: 11%;
        top: 38%;
        left: 27%;
      }
    }
    .dropBox-2 {
      div {
        // background:#000;
        // opacity:0.5;
        width: 19%;
        height: 13%;
        top: 50%;
        left: 26%;
      }
    }
    .dropBox-3 {
      div {
        // background:#000;
        // opacity:0.5;
        width: 20%;
        height: 18%;
        top: 22%;
        left: 52%;
      }
    }
    .dropBox-4 {
      div {
        // background:#000;
        // opacity:0.5;
        width: 16%;
        height: 10%;
        top: 41%;
        left: 53.5%;
      }
    }
  }

  .lesson-35 {
    .drag-area {
      .option-1 {
        top: 20%;
        left: 75%;
        width: 10%;
      }
      .option-2 {
        top: 30%;
        left: 3%;
        width: 18%;
      }
      .option-3 {
        top: 25%;
        left: 90%;
        width: 4%;
      }
      .option-4 {
        top: 37%;
        left: 84%;
        width: 10%;
      }
    }
    .dropBox-1 {
      div {
        // background:#000;
        // opacity:0.5;
        width: 8%;
        height: 11%;
        top: 28%;
        left: 27%;
      }
    }
    .dropBox-2 {
      div {
        // background:#000;
        // opacity:0.5;
        width: 23%;
        height: 28%;
        top: 28%;
        left: 52%;
      }
    }
    .dropBox-3 {
      div {
        // background:#000;
        // opacity:0.5;
        width: 10%;
        height: 10%;
        top: 41%;
        left: 57%;
      }
    }
    .dropBox-4 {
      div {
        // background:#000;
        // opacity:0.5;
        width: 10%;
        height: 18%;
        top: 30%;
        left: 16%;
      }
    }
  }
  .lesson-57 {
    .drag-area {
      .option-1 {
        top: 10.5%;
        left: 88%;
        width: 10%;
      }
      .option-2 {
        top: 28.5%;
        left: 88%;
        width: 10%;
      }
      .option-3 {
        top: 27.5%;
        left: 4%;
        width: 10%;
      }
      .option-4 {
        top: 63%;
        left: 4%;
        width: 10%;
      }
      .option-5 {
        top: 46%;
        left: 88%;
        width: 10%;
      }
      .option-6 {
        top: 80.5%;
        left: 4%;
        width: 10%;
      }
      .option-7 {
        top: 45%;
        left: 4%;
        width: 10%;
      }
      .option-8 {
        top: 63%;
        left: 88%;
        width: 10%;
      }
    }
    .dropBox-1 {
      div {
        // background:#000;
        // opacity:0.5;
        width: 10%;
        height: 14%;
        top: 32%;
        left: 39%;
      }
    }
    .dropBox-2 {
      div {
        // background:#000;
        // opacity:0.5;
        width: 10%;
        height: 14%;
        top: 32%;
        left: 51%;
      }
    }
    .dropBox-3 {
      div {
        // background:#000;
        // opacity:0.5;
        width: 10%;
        height: 14%;
        top: 50%;
        left: 28%;
      }
    }
    .dropBox-4 {
      div {
        // background:#000;
        // opacity:0.5;
        width: 10%;
        height: 14%;
        top: 50%;
        left: 39%;
      }
    }
    .dropBox-5 {
      div {
        // background:#000;
        // opacity:0.5;
        width: 10%;
        height: 14%;
        top: 50%;
        left: 51%;
      }
    }
    .dropBox-6 {
      div {
        // background:#000;
        // opacity:0.5;
        width: 10%;
        height: 14%;
        top: 67%;
        left: 39%;
      }
    }
    .dropBox-7 {
      div {
        // background:#000;
        // opacity:0.5;
        width: 10%;
        height: 14%;
        top: 67%;
        left: 51%;
      }
    }
    .dropBox-8 {
      div {
        // background:#000;
        // opacity:0.5;
        width: 10%;
        height: 14%;
        top: 67%;
        left: 63%;
      }
    }
  }

  .lesson-611 {
    .drag-area {
      .option-1 {
        top: 48%;
        left: 2%;
        width: 8%;
      }
      .option-2 {
        top: 15.5%;
        left: 82%;
        width: 8%;
      }
      .option-3 {
        top: 38%;
        left: 80%;
        width: 8%;
      }
      .option-4 {
        top: 38%;
        left: 90%;
        width: 8%;
      }
      .option-5 {
        top: 61.5%;
        left: 91%;
        width: 8%;
      }
      .option-6 {
        top: 48%;
        left: 10%;
        width: 8%;
      }
      .option-7 {
        top: 64%;
        left: 6%;
        width: 8%;
      }
      .option-8 {
        top: 15.5%;
        left: 90%;
        width: 8%;
      }
      .option-9 {
        top: 61.5%;
        left: 82%;
        width: 8%;
      }
    }
    .dropBox-1 {
      div {
        // background:#000;
        // opacity:0.5;
        width: 6.5%;
        height: 10%;
        top: 32.5%;
        left: 31%;
      }
    }
    .dropBox-2 {
      div {
        // background:#000;
        // opacity:0.5;
        width: 6.5%;
        height: 10%;
        top: 32.5%;
        left: 38%;
      }
    }
    .dropBox-3 {
      div {
        // background:#000;
        // opacity:0.5;
        width: 6.5%;
        height: 10%;
        top: 32.5%;
        left: 44.5%;
      }
    }
    .dropBox-4 {
      div {
        // background:#000;
        // opacity:0.5;
        width: 6.5%;
        height: 10%;
        top: 32.5%;
        left: 51.5%;
      }
    }
    .dropBox-5 {
      div {
        // background:#000;
        // opacity:0.5;
        width: 6.5%;
        height: 10%;
        top: 32.5%;
        left: 58%;
      }
    }
    .dropBox-6 {
      div {
        //  background:#000;
        // opacity:0.5;
        width: 6.5%;
        height: 10%;
        top: 32.5%;
        left: 64.5%;
      }
    }
    .dropBox-7 {
      div {
        // background:#000;
        // opacity:0.5;
        width: 6.5%;
        height: 10%;
        top: 52%;
        left: 25%;
      }
    }
    .dropBox-8 {
      div {
        // background:#000;
        // opacity:0.5;
        width: 6.5%;
        height: 10%;
        top: 61.5%;
        left: 51.5%;
      }
    }
    .dropBox-9 {
      div {
        // background:#000;
        // opacity:0.5;
        width: 6.5%;
        height: 10%;
        top: 71%;
        left: 38%;
      }
    }
  }
}
</style>
